<script setup lang="ts">
import * as api from "@/api";
import { getValue, setValue } from "@/code-editor";
import globals from "@/globals";
import {
	getDomain,
	getFullDomain,
	getSuffix,
	loadSites,
	openSite,
} from "@/site";
import { LoginInfo } from "@/types";
import { getPremium, showDialog, sleep } from "@/utils";
import { t } from "i18next";
import { onMounted, ref, useTemplateRef } from "vue";

const props = defineProps<{
	isClosing: boolean;
	login: LoginInfo;
	oldDomain: string;
	premiumRemaining: number;
	sites: string[];
}>();

const time = Date.now();
const defaultDomain =
	props.oldDomain ||
	getDomain(props.login.email?.split("@")[0], time.toString(36));
const defaultFullDomain = getFullDomain(defaultDomain);
const defaultTip = t("defaultDomainTip", {
	suffix: getSuffix(),
});
const isLeftAlign =
	props.sites.length === 0 && window.innerWidth > 900 && getValue();

const inputRef = useTemplateRef<HTMLInputElement>("input");
const inputValue = ref<string>(defaultFullDomain);
const isTutorialShown = ref<boolean>(false);
const tip = ref<string>(defaultTip);

async function closePopup(): Promise<void> {
	globals.state.isClosingDialog = true;
	await sleep(globals.ANIMATION_WAIT_TIME);
	globals.state.isAddSitePopupShown = false;
	globals.state.isClosingDialog = false;
}

function handleChange(): void {
	const domain = getDomain(inputValue.value, defaultDomain);
	if (
		domain.includes(".") &&
		!domain.startsWith(".") &&
		!inputValue.value.endsWith(getSuffix())
	) {
		tip.value =
			props.premiumRemaining > 0
				? t("customDomainTip", { cname: domain + ".cname.rthe.cn" })
				: t("premiumRequiredForUsingCustomDomains") +
					t("refreshIfJustPaid");
		isTutorialShown.value = true;
	} else {
		tip.value = defaultTip;
		isTutorialShown.value = false;
	}
}

async function handleSubmit(event: Event): Promise<void> {
	event.preventDefault();
	if (!props.login.username) {
		return;
	}
	let domain = getDomain(inputValue.value, defaultDomain);
	if (domain.includes(".")) {
		if (inputValue.value.endsWith(getSuffix())) {
			domain = domain.replaceAll(".", "-");
		} else if (props.premiumRemaining <= 0) {
			domain = "";
			void closePopup();
			await showDialog(
				t("premiumRequiredForUsingCustomDomains") +
					t("refreshIfJustPaid"),
				{
					showCancel: true,
				},
			);
			void getPremium();
		}
	}
	if (!domain) {
		return;
	}
	const fullDomain = getFullDomain(domain);
	inputValue.value = domain.includes(".") ? domain : fullDomain;
	tip.value = t("registering", {
		domain: inputValue.value,
	});
	try {
		const data = await api.createSite(domain, props.oldDomain, async () => {
			await closePopup();
			await sleep(globals.ANIMATION_WAIT_TIME);
			await showDialog(
				t("premiumRequiredForCreatingMultipleSites") +
					t("refreshIfJustPaid"),
				{
					showCancel: true,
				},
			);
			void getPremium();
		});
		if (data.success) {
			void closePopup();
			setValue();
			void loadSites();
			if (data.domain) {
				void openSite(getFullDomain(data.domain));
			}
		} else if (data.alert) {
			tip.value = t("error") + data.alert;
		}
	} catch (error) {
		tip.value = defaultTip;
		void api.handleApiError(error);
	}
	try {
		const data = await api.getStatus();
		if (data?.redirect) {
			window.location.href = data.redirect;
		}
	} catch (error) {
		void api.handleApiError(error);
	}
}

onMounted(() => {
	handleChange();
	inputRef.value?.focus();
});
</script>

<template>
	<focus-trap
		:active="true"
		@deactivate="closePopup"
	>
		<div
			class="overlay"
			:class="{ 'fade-in': !isClosing, 'fade-out': isClosing }"
		>
			<form
				class="popup"
				:class="{
					'fade-in': !isClosing,
					'fade-out': isClosing,
					'left-align': isLeftAlign,
				}"
				role="dialog"
				@submit="handleSubmit"
			>
				<h1>{{ $t("setDomainName") }}</h1>
				<input
					ref="input"
					class="input-box"
					v-model="inputValue"
					:placeholder="defaultFullDomain"
					@input="handleChange"
				/>
				<div class="tip">
					{{ tip }}
					<a
						v-if="isTutorialShown"
						href="https://docs.retiehe.com/host/own-domain.html"
						target="_blank"
						rel="noopener"
					>
						{{ $t("viewTutorial") }}
					</a>
				</div>
				<div class="btn-bar">
					<button
						class="cancel-btn"
						type="button"
						@click="closePopup"
					>
						{{ $t("cancel") }}
					</button>
					<button
						class="default-btn"
						type="submit"
					>
						{{ $t("ok") }}
					</button>
				</div>
			</form>
		</div>
	</focus-trap>
</template>
