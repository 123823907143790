import * as api from "@/api";
import { changeEditorFontSize, setValue } from "@/code-editor";
import { configExists, getFolderName, openFile } from "@/file";
import globals from "@/globals";
import { saveSettings } from "@/settings";
import { getDomain, getSuffix, loadSites, newSite, rmBackup } from "@/site";
import {
	Column,
	ContextMenuInfo,
	DialogInfo,
	MenuItemInfo,
	PromptInfo,
	RealIdLevel,
	SiteContent,
} from "@/types";
import { deleteWallpaper } from "@/wallpaper";
import i18n, { t } from "i18next";

let openedLoginPage: Window | null = null;

export async function closeAiBar(): Promise<void> {
	if (!globals.state.isAiBarShown) {
		return;
	}
	globals.state.isClosingSidebar = true;
	document.documentElement.classList.remove("has-sidebar");
	await sleep(globals.ANIMATION_WAIT_TIME);
	globals.state.isAiBarShown = false;
	globals.state.isClosingSidebar = false;
}

export async function closeDialog(): Promise<void> {
	if (!globals.state.dialog.text) {
		return;
	}
	globals.dialogCallbacks = {};
	globals.state.isClosingDialog = true;
	await sleep(globals.ANIMATION_WAIT_TIME);
	globals.state.dialog = {};
	globals.state.isClosingDialog = false;
}

export async function closeSidebars(): Promise<void> {
	await closeAiBar();
	await closeUploadCenter();
}

export async function closeUploadCenter(): Promise<void> {
	if (globals.state.pendingUploadFiles.length === 0) {
		return;
	}
	globals.state.isClosingSidebar = true;
	await sleep(globals.ANIMATION_WAIT_TIME);
	globals.state.pendingUploadFiles = [];
	globals.pendingUploadFiles = {};
	globals.state.isClosingSidebar = false;
}

export async function copyText(text: string): Promise<void> {
	await navigator.clipboard.writeText(text);
	void showToast(t("copiedToClipboard"));
}

export function encodeData(
	data: Record<string, string | null | undefined>,
): string {
	const array: string[] = [];
	for (const key in data) {
		const value = data[key];
		if (!value) {
			continue;
		}
		array.push(key + "=" + encodeURIComponent(value));
	}
	return array.join("&");
}

export function downloadDataUrl(dataUrl: string, filename: string): void {
	const newLink = document.createElement("a");
	newLink.hidden = true;
	newLink.href = dataUrl;
	newLink.download = filename;
	document.body.appendChild(newLink);
	newLink.click();
	newLink.remove();
}

export function downloadText(text: string, filename: string): void {
	downloadDataUrl(
		"data:text/plain;charset=utf-8," + encodeURIComponent(text),
		filename,
	);
}

export function getRthCdnBaseUrl(): string {
	return isMainland()
		? "https://cdn.rthe.cn/"
		: "https://cdn.rthsoftware.cn/";
}

export function handleKeyboardClick(
	event: KeyboardEvent,
	onClick: () => void,
): void {
	switch (event.key) {
		case "Enter":
		case " ": {
			onClick();
			event.preventDefault();
			break;
		}
		default:
			break;
	}
}

export function finishLoading(): void {
	if (
		globals.dynamicInfo.loaded &&
		globals.loadCount >=
			(globals.login.username ? globals.expectedLoadCount : 1)
	) {
		globals.state.isSplashScreenShown = false;
		if (globals.hasRealId && globals.state.sites.length === 0) {
			newSite();
		}
	}
}

export async function getPremium(): Promise<void> {
	const handleVisibilityChange = (): void => {
		if (!document.hidden) {
			void loadExpirationTime();
			removeEventListener("visibilitychange", handleVisibilityChange);
		}
	};

	window.open(
		"https://account.retiehe.com/premium?" +
			encodeData({
				lang: i18n.language,
				service: "Rthe",
				test: process.env.NODE_ENV === "development" ? "1" : undefined,
			}),
	);
	addEventListener("visibilitychange", handleVisibilityChange);
	await sleep(globals.ANIMATION_WAIT_TIME);
	await showDialog(t("refreshIfPaid"), {
		showCancel: true,
	});
	window.location.reload();
}

export function isMainland(): boolean {
	if (!globals.dynamicInfo.region) {
		const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
		return timeZone === "Asia/Shanghai";
	}
	return globals.dynamicInfo.region === "CN";
}

export function isPremium(): boolean {
	if (globals.privilege) {
		return true;
	} else {
		showDialog(t("premiumOnly") + t("refreshIfJustPaid"), {
			showCancel: true,
		})
			.then(getPremium)
			.catch(console.error);
		return false;
	}
}

export async function loadExpirationTime(): Promise<void> {
	try {
		const data = await api.getExpirationTime();
		globals.state.premiumRemaining = data.time
			? Math.round((data.time - Date.now() / 1000) / 86400)
			: 0;
		globals.privilege = data.encrypted || false;
		globals.state.hasPremium = !!globals.privilege;
		if (
			(globals.privilege &&
				!globals.isTencent &&
				!navigator.userAgent.includes("HeyTapBrowser") &&
				!navigator.userAgent.includes("HuaweiBrowser")) ||
			globals.login.email?.endsWith("@uw.edu")
		) {
			globals.state.sites = globals.state.sites.slice();
			if (globals.state.currentSite) {
				globals.state.currentSite =
					getDomain(globals.state.currentSite) + getSuffix();
			}
		}
		if (
			globals.state.premiumRemaining > 0 &&
			globals.state.premiumRemaining < 3
		) {
			showFloatNotification({
				onClick: () => {
					window.open(
						"https://account.retiehe.com/?" +
							encodeData({
								page: "premium",
								service: "host",
							}),
					);
				},
				text: t("renewBeforeExpiresToGet20PercentOff"),
				title: t("premiumExpiresWithin3Days"),
			});
		}
	} catch (error) {
		void api.handleApiError(error);
		globals.privilege = false;
	}
}

export function loggedIn(isNewLogin: boolean): void {
	globals.state.login = globals.login;
	if (isNewLogin) {
		globals.state.isLoginPanelShown = false;
	}
	globals.state.currentColumn = Column.SiteList;
	loadSites()
		.then(async () => {
			globals.loadCount++;
			if (globals.state.sites.length === 0) {
				if (
					globals.hasRealId &&
					globals.loadCount >= globals.expectedLoadCount
				) {
					newSite();
				}
			} else {
				const openedHost = localStorage.getItem("CurrentHostname");
				if (openedHost) {
					if (globals.state.sites.includes(getDomain(openedHost))) {
						await showDialog(
							t("confirmRestoreUnsavedData"),
							{
								showCancel: true,
							},
							{
								cancel: rmBackup,
							},
						);
						globals.state.currentSite = openedHost;
						globals.state.files = JSON.parse(
							localStorage.getItem("CurrentHost") || "{}",
						) as SiteContent;
						globals.currentHostInitial = JSON.parse(
							JSON.stringify(globals.state.files),
						) as SiteContent;
						const siteContent = globals.state.files;
						if (!configExists(siteContent)) {
							return;
						}
						const config = siteContent["settings.rth"];
						globals.state.currentFile = config.home;
						globals.state.currentFolder = getFolderName(
							config.home,
						);
						void openFile(config.home);
					} else {
						rmBackup();
					}
				}
			}
			finishLoading();
		})
		.catch(console.error);
	loadExpirationTime()
		.then(async () => {
			if (globals.privilege) {
				try {
					const data = await api.getSettings();
					if (
						data.fontSize &&
						globals.settings.fontSize !== data.fontSize
					) {
						globals.settings.fontSize = Number(data.fontSize);
						changeEditorFontSize(false);
					}
					if (data.jsProcessor) {
						globals.settings.jsProcessor = data.jsProcessor;
					}
					saveSettings();
				} catch (error) {
					void api.handleApiError(error);
				}
			} else if (globals.settings.theme === "wallpaper") {
				globals.settings.theme = "dark";
				saveSettings();
				void deleteWallpaper();
				document.documentElement.classList.remove("has-bg-img");
			}
			globals.loadCount++;
			finishLoading();
		})
		.catch(console.error);
	api.getIntroForLoggedIn()
		.then((data) => {
			if (data && !globals.state.currentSite) {
				setValue(data);
			}
		})
		.catch(api.handleApiError);
	api.getRealId()
		.then((data) => {
			globals.loadCount++;
			globals.hasRealId = data.realIdLevel >= RealIdLevel.PHONE_VERIFIED;
			if (!globals.hasRealId) {
				globals.newSiteOverride = (): void => {
					void showRealIdDialog();
				};
				void showRealIdDialog();
			}
			finishLoading();
		})
		.catch((error) => {
			void api.handleApiError(error);
			globals.newSiteOverride = (): void => {
				void showDialog(t("essentialComponentNotLoaded"));
			};
		});
}

export function logIn(): void {
	openedLoginPage?.close();
	openedLoginPage = window.open(
		"https://account.retiehe.com/login?" +
			encodeData({
				appname: globals.APP_NAME,
				page: "login",
				region: globals.dynamicInfo.region,
				test: process.env.NODE_ENV === "development" ? "1" : undefined,
				theme: globals.isLight ? "light" : "dark",
			}),
	);
}

export function parseJson<T>(str: string): T {
	try {
		return JSON.parse(str) as T;
	} catch (error) {
		console.error(error);
		return {} as T;
	}
}

export function showContextMenu(
	event: MouseEvent | undefined,
	menuItems: {
		content: MenuItemInfo;
		onClick: () => void | Promise<void>;
		when: boolean;
	}[],
): void {
	const newContextMenuInfo: ContextMenuInfo = {
		items: [],
		x: event?.x || 0,
		y: event?.y || 0,
	};
	globals.contextMenuCallbacks = {};
	for (const item of menuItems) {
		if (!item.when) {
			continue;
		}
		newContextMenuInfo.items.push(item.content);
		globals.contextMenuCallbacks[item.content.text] = item.onClick;
	}
	globals.state.contextMenuInfo = newContextMenuInfo;
}

export function showDialog(
	text: string,
	options: DialogInfo = {},
	callbacks?: typeof globals.dialogCallbacks,
): Promise<void> {
	return new Promise<void>((resolve) => {
		if (callbacks) {
			globals.dialogCallbacks = callbacks;
		}
		if (!globals.dialogCallbacks.ok) {
			globals.dialogCallbacks.ok = (): void => {
				resolve();
			};
		}
		options.text = text;
		globals.state.dialog = options;
	});
}

export function showFloatNotification({
	isFolded,
	onClick,
	onClose,
	text,
	title,
}: {
	isFolded?: boolean;
	onClick?: () => void;
	onClose?: () => void;
	text: string;
	title: string;
}): void {
	globals.floatNotificationCallbacks.onClick = onClick || null;
	globals.floatNotificationCallbacks.onClose = onClose || null;
	globals.state.floatNotificationInfo = {
		isFolded: isFolded || false,
		text: text,
		title: title,
	};
}

export function showPrompt(
	text: string,
	options: PromptInfo = {},
): Promise<string> {
	return new Promise<string>((resolve) => {
		options.text = text;
		globals.state.prompt = options;
		globals.promptCallback = resolve;
	});
}

export async function showRealIdDialog(): Promise<void> {
	await closeDialog();
	await showDialog(t("requireRealId"), {
		title: t("lastStep").toString(),
	});
	window.location.href =
		"https://account.retiehe.com/?" +
		encodeData({
			action: "changeRealName",
			lang: i18n.language,
			test: process.env.NODE_ENV === "development" ? "1" : undefined,
		});
}

export async function showToast(text: string, timeout?: number): Promise<void> {
	if (globals.state.toast.text) {
		globals.state.toast = {};
		await sleep(1);
	}
	globals.state.toast = {
		text: text,
		timeout: timeout,
	};
}

export function sleep(delay: number): Promise<void> {
	return new Promise((resolve) => {
		setTimeout(resolve, delay);
	});
}
