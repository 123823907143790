import { changeTheme } from "@/settings";
import {
	AppState,
	DynamicInfo,
	LoginInfo,
	SettingsInfo,
	SiteContent,
} from "@/types";

type CustomCallbacks = Record<
	string,
	(event?: MouseEvent) => void | Promise<void>
>;

const globals = {
	accept: /(\.(html?|css|jsx?|json|md|rth|tsx?|txt|vue|xml)|\/)$/i,
	ANIMATION_WAIT_TIME: 250 as const,
	APP_NAME: "host" as const,
	BACKEND: process.env.RTH_BACKEND || "",
	BACKEND_HOST: process.env.RTH_BACKEND_HOST || "",
	BACKEND_HOST_BACKUP: "https://dragon.retiehe.com/backend/host/" as const,
	BACKEND_TERMINAL: "http://localhost:8880/host/",
	browserCheck: {
		grade: 0,
		isBadDns: false,
	},
	chromeVer:
		parseInt(/Chrome\/([\d]+)/i.exec(navigator.userAgent)?.[1] || "0") || 0,
	contextMenuCallbacks: {} as CustomCallbacks,
	currentHostInitial: {} as SiteContent,
	dialogCallbacks: {} as CustomCallbacks,
	dragged: "",
	dynamicInfo: {} as DynamicInfo,
	expectedLoadCount: 4,
	floatNotificationCallbacks: {
		onClick: null as (() => void) | null,
		onClose: null as (() => void) | null,
	},
	hasRealId: false,
	isBackup: false,
	isChrome: navigator.userAgent.includes("Chrome/"),
	isLight: false,
	isMac: navigator.userAgent.includes("Macintosh"),
	isMobile:
		navigator.userAgent.includes("Android") ||
		navigator.userAgent.includes("iPhone") ||
		navigator.userAgent.includes("iPad") ||
		(navigator.userAgent.includes("Macintosh") &&
			navigator.maxTouchPoints > 0) ||
		navigator.userAgent.includes("OpenHarmony"),
	isMonacoSupported: false,
	isSafari: false,
	isTencent:
		navigator.userAgent.includes("MicroMessenger") ||
		navigator.userAgent.includes("QQ") ||
		navigator.userAgent.includes("SE 2.X MetaSr 1.0"),
	loadCount: 0,
	login: {} as LoginInfo,
	newSiteOverride: null as (() => void) | null,
	openedPage: null as Window | null,
	params: Object.fromEntries(
		new URLSearchParams(window.location.search).entries(),
	),
	pendingUploadFiles: {} as Record<string, File>,
	privilege: null as string | false | null,
	promptCallback: null as ((newValue: string) => void | Promise<void>) | null,
	settings: ((): SettingsInfo => {
		const defaultSettings = {
			fontSize: 14,
			jsProcessor: null,
			theme: "system",
		};
		try {
			const settings = localStorage.getItem("Settings");
			if (settings) {
				return JSON.parse(settings) as SettingsInfo;
			}
		} catch (error) {
			console.error(error);
			return defaultSettings;
		}
		return defaultSettings;
	})(),
	socket: null as WebSocket | null,
	state: {} as AppState,
	SUFFIXES: {
		FREE: ".rth1.xyz",
		PREMIUM: ".rth2.xyz",
	} as const,
	themeColor: document.createElement("meta"),
};

globals.isLight =
	globals.settings.theme === "light" ||
	(globals.settings.theme === "system" &&
		((): boolean => {
			const themeMatch = matchMedia("(prefers-color-scheme: light)");
			themeMatch.onchange = (event): void => {
				if (globals.settings.theme === "system") {
					changeTheme(event.matches);
				}
			};
			return themeMatch.matches;
		})());
globals.isMonacoSupported = globals.chromeVer === 0 || globals.chromeVer >= 85;
globals.isSafari = !globals.isChrome && navigator.userAgent.includes("Safari/");

export default globals;
