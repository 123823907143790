export interface ApiKeyInfo {
	apiKey: string;
	createdAt: string;
	id: string;
	isDisabled?: boolean;
	lastUsedAt?: string;
}

export interface AppState {
	Column: typeof Column;
	changedFiles: string[];
	contextMenuInfo: ContextMenuInfo | null;
	currentColumn: Column;
	currentFile: string;
	currentFolder: string;
	currentSite: string;
	dialog: DialogInfo;
	files: SiteContent;
	floatNotificationInfo: FloatNotificationInfo | null;
	hasPremium: boolean | null;
	hasUnsaved: boolean;
	isAddSitePopupShown: boolean;
	isAiBarShown: boolean;
	isApiKeysPopupShown: boolean;
	isClosingDialog: boolean;
	isClosingSidebar: boolean;
	isClosingToast: boolean;
	isLoadingScreenShown: boolean;
	isLoginPanelShown: boolean;
	isMainMenuShown: boolean;
	isMonacoEnabled: boolean;
	isSettingsPopupShown: boolean;
	isSplashScreenShown: boolean;
	isSslPopupShown: boolean;
	login: LoginInfo;
	oldDomain: string;
	pendingUploadFiles: FileUploadInfo[];
	premiumRemaining: number;
	prompt: PromptInfo;
	selectedFiles: string[];
	sites: string[];
	toast: ToastInfo;
}

export interface BinaryFile {
	hash?: string;
	md5?: string;
	type?: string;
	url?: string;
}

export enum Column {
	SiteList,
	FileList,
	CodeEditor,
}

export interface ContextMenuInfo {
	items: MenuItemInfo[];
	x?: number;
	y?: number;
}

export interface DialogInfo {
	countdown?: number;
	disableOk?: boolean;
	extraButtons?: string[];
	isHtml?: boolean;
	showCancel?: boolean;
	text?: string;
	title?: string;
}

export interface DynamicInfo {
	alert?: string;
	blockReason?: string;
	isTwinkstar?: boolean;
	loaded?: boolean;
	login?: string;
	qqGroup?: string;
	redirectTo?: string;
	region?: string;
	sw?: boolean;
}

export interface FileUploadInfo {
	path: string;
	skipReason: string;
	status: FileUploadStatus;
	type: string;
}

export enum FileUploadStatus {
	NOT_UPLOADED,
	UPLOADING,
	UPLOADED,
	FAILED,
}

export interface FloatNotificationInfo {
	isFolded: boolean;
	text: string;
	title: string;
}

export interface LoginInfo {
	birthday?: string;
	email?: string;
	name?: string;
	phone?: string;
	token?: string;
	username?: string;
}

export interface MenuItemInfo {
	icon?: string;
	text: string;
}

export enum PresetSaveCallback {
	DO_NOTHING,
	SHOW_TOAST,
	SHOW_DIALOG,
}

export interface PromptInfo {
	dataList?: string[];
	defaultText?: string;
	text?: string;
}

export enum RealIdLevel {
	NO_REAL_ID,
	EMAIL_VERIFIED,
	PHONE_VERIFIED,
	REAL_ID_VERIFIED,
}

export interface SettingsInfo {
	codeEditor?: string | null;
	fontSize: number;
	jsProcessor: string | null;
	language?: string | null;
	theme: string;
	[key: string]: string | number | null | undefined;
}

export interface SettingsItemInfo {
	confirm?: {
		text: string;
		when?: (newValue: string) => boolean;
	};
	default: boolean | number | string;
	key: string;
	label: string;
	onChange?: (
		newValue: string,
		oldValue?: string,
		target?: HTMLInputElement | HTMLSelectElement,
	) => void | Promise<void>;
	options?: (
		| string
		| {
				text: string;
				value: string;
				when?: boolean;
		  }
	)[];
	reloadRequired?: (newValue: string) => boolean;
}

export interface SiteConfig {
	home: string;
	ip?: string;
	isDeactivated?: boolean;
	password?: string;
	referrer?: string;
	time?: string;
	username?: string;
}

export interface SiteContent {
	"settings.rth": SiteConfig;
	[key: string]: string | BinaryFile | SiteConfig | undefined;
}

export interface ToastInfo {
	text?: string;
	timeout?: number;
}

export interface UploadResponse {
	alert?: string;
	errorLog?: string;
	modified?: Record<string, string>;
	success?: boolean;
}

export interface UploadSiteRequest {
	content?: string | File;
	domain: string;
	key: string;
	keys: string;
	privilege?: string;
	processor?: string;
	type: string;
	[key: string]: string | File | undefined;
}
