<script setup lang="ts">
import * as api from "@/api";
import { getSelection, getValue, setValue } from "@/code-editor";
import { handleSaveClick } from "@/file";
import globals from "@/globals";
import { BinaryFile, SiteConfig, SiteContent } from "@/types";
import { closeAiBar, isPremium, showDialog, sleep } from "@/utils";
import { t } from "i18next";
import { ref, useId } from "vue";

type AiRequestType =
	| "writeNewHtmlCode"
	| "editExistingHtmlCode"
	| "askQuestion"
	| "askQuestionAboutCurrentSiteCode";

const props = defineProps<{
	currentFile: string;
	files: SiteContent;
	isClosing: boolean;
}>();

const aiRequestId = useId();
const aiResponse = ref<string>("");
const askQuestionId = useId();
const askQuestionAboutCurrentSiteCodeId = useId();
const editExistingCodeId = useId();
const isAiLoading = ref<boolean>(false);
const requestText = ref<string>("");
const type = ref<AiRequestType>("writeNewHtmlCode");
const writeNewCodeId = useId();

async function askQuestion(): Promise<void> {
	if (!isPremium()) {
		return;
	}
	let question = "";
	const selection = getSelection();
	if (selection) {
		question = t("codeUserSelected") + "\n" + selection + "\n";
	}
	if (type.value === "askQuestionAboutCurrentSiteCode") {
		question += t("thisIsMyCurrentCode") + "\n";
		const filesCopy: Record<
			string,
			string | BinaryFile | SiteConfig | undefined
		> = {
			...props.files,
		};
		delete filesCopy["settings.rth"];
		const siteContent = JSON.stringify(filesCopy);
		if (siteContent.length <= 128_000) {
			question +=
				siteContent +
				"\n" +
				t("currentFile") +
				props.currentFile +
				"\n";
		} else {
			question += getValue() + "\n" + t("thisIsFileList") + "\n";
			for (const key in props.files) {
				question += key + "\n";
			}
		}
	}
	if (question) {
		question += "\n";
	}
	question += requestText.value;
	isAiLoading.value = true;
	try {
		const result = await api.sendMessageToAi(question, {
			isHtmlOnly: false,
		});
		if (result.error?.message) {
			void showDialog(result.error.message);
			return;
		}
		const message = result.choices?.[0].message.content;
		if (!message) {
			throw new Error();
		}
		aiResponse.value = message;
	} catch (error) {
		console.error(error);
		await sleep(globals.ANIMATION_WAIT_TIME);
		void showDialog(t("aiEncounteredDifficultiesRetry"));
	} finally {
		isAiLoading.value = false;
	}
}

async function generateCode(): Promise<void> {
	if (!isPremium()) {
		return;
	}
	if (!/(\.html?|\/)$/.test(props.currentFile)) {
		void showDialog(t("currentFileIsNotHtmlFile"));
		return;
	}
	await showDialog(t("thisFileWillBeReplacedWithCodeWrittenByAi"), {
		countdown: 3,
		showCancel: true,
	});
	globals.state.isLoadingScreenShown = true;
	try {
		const result = await api.sendMessageToAi(
			requestText.value +
				(type.value === "editExistingHtmlCode"
					? `\n${t("thisIsMyCurrentCode")}\n${getValue()}`
					: ""),
			{ isHtmlOnly: true },
		);
		if (result.error?.message) {
			void showDialog(result.error.message);
			return;
		}
		const code = result.choices?.[0].message.content;
		if (!code) {
			throw new Error();
		}
		setValue(code);
		handleSaveClick();
	} catch (error) {
		console.error(error);
		await sleep(globals.ANIMATION_WAIT_TIME);
		void showDialog(t("aiEncounteredDifficultiesRetry"));
	} finally {
		globals.state.isLoadingScreenShown = false;
	}
}

function reset(): void {
	aiResponse.value = "";
	requestText.value = "";
}
</script>

<template>
	<sidebar-shell
		:is-closing="isClosing"
		:close-sidebar="closeAiBar"
	>
		<template #title>
			<ai-icon class="icon" />
			<div
				v-if="aiResponse || isAiLoading"
				class="text"
			>
				{{ $t("aiProgrammer") }}
			</div>
			<label
				v-else
				class="text"
				:for="aiRequestId"
				>{{ $t("describeYourNeeds") }}</label
			>
		</template>
		<template #content>
			<div
				v-if="aiResponse || isAiLoading"
				class="message-area"
			>
				<chat-message
					:is-loading="false"
					:message="requestText"
				/>
				<chat-message
					:is-loading="isAiLoading"
					:message="aiResponse"
				/>
			</div>
			<template v-else>
				<textarea
					v-model="requestText"
					:id="aiRequestId"
					class="input-box"
					:placeholder="t('aiRequestExample').toString()"
					@click="isPremium"
				></textarea>
				<div class="input-bar">
					<input
						v-model="type"
						:id="writeNewCodeId"
						type="radio"
						name="ai-action"
						value="writeNewHtmlCode"
						checked
					/>
					<label :for="writeNewCodeId">{{
						$t("writeNewHtmlCode")
					}}</label>
				</div>
				<div class="input-bar">
					<input
						v-model="type"
						:id="editExistingCodeId"
						type="radio"
						name="ai-action"
						value="editExistingHtmlCode"
					/>
					<label :for="editExistingCodeId">{{
						$t("editExistingHtmlCode")
					}}</label>
				</div>
				<div class="input-bar">
					<input
						v-model="type"
						:id="askQuestionId"
						type="radio"
						name="ai-action"
						value="askQuestion"
					/>
					<label :for="askQuestionId">
						{{ $t("askQuestion") }}
						<div class="input-bar-description">
							{{ $t("askQuestionDescription") }}
						</div>
					</label>
				</div>
				<div class="input-bar">
					<input
						v-model="type"
						:id="askQuestionAboutCurrentSiteCodeId"
						type="radio"
						name="ai-action"
						value="askQuestionAboutCurrentSiteCode"
					/>
					<label :for="askQuestionAboutCurrentSiteCodeId">
						{{ $t("askQuestionAboutCurrentSiteCode") }}
						<div class="input-bar-description">
							{{
								$t("askQuestionAboutCurrentSiteCodeDescription")
							}}
						</div>
					</label>
				</div>
			</template>
			<p class="warning-box">
				<font-awesome-icon icon="circle-exclamation" />{{
					$t("aiProgrammerDisclaimer")
				}}
			</p>
			<button
				v-if="aiResponse || isAiLoading"
				:disabled="isAiLoading"
				type="button"
				@click="reset"
			>
				{{ $t("newQuestion") }}
			</button>
			<button
				v-else-if="
					type === 'askQuestion' ||
					type === 'askQuestionAboutCurrentSiteCode'
				"
				:disabled="!requestText"
				type="submit"
				@click="askQuestion"
			>
				{{ $t("send") }}
			</button>
			<button
				v-else
				:disabled="!requestText"
				type="submit"
				@click="generateCode"
			>
				{{ $t("generate") }}
			</button>
		</template>
	</sidebar-shell>
</template>

<style scoped>
.input-bar {
	margin: 5px 0;
}

.input-bar-description {
	color: var(--fg-alpha-5);
	font-size: 13px;
}

.input-box {
	flex-grow: 1;
	line-height: 1.5;
	resize: none;
}

.message-area {
	flex: 1;
	flex-direction: column;
	margin-bottom: 5px;
	overflow: auto;
}

.warning-box {
	background-color: var(--warning-color-tint);
	border-radius: 5px;
	color: var(--warning-color);
	line-height: 1.5;
	margin: 5px 0 10px;
	padding: 10px;
}

.warning-box svg {
	margin-right: 6px;
}
</style>
